.login-popup-modal {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: rgba(0, 0, 0, .7); */
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 999;
}
.overlay-modal {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgba(0, 0, 0, .7);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    /* z-index: 999; */
}

.login-popup {
    background-color: #fff;
    border-radius: 8px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    flex-direction: column;
    width: 320px;
    padding: 20px;
    position: relative;
}

.login-popup {
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.login-popup-btn {
    font-family: "Kanit";
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #E86029;
    border: none;
    padding: 8px 30px;
    border-radius: 8px;
}