.AllproductCategory-container {
    width: 25%;
    position: relative;
    position: sticky;
    top: 20px;
    height: fit-content;
}

.AllproductCategory-container h2 {
    font-size: 18px !important;
    font-family: "Kanit";
    font-weight: 600;
    color: black;
}

.AllproductCategory {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.SelectedCategory {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.AllproductCategory:hover .AllproductCategory-icon {
    color: #E86029;
}

.AllproductCategory:hover .AllproductCategory-text {
    color: #E86029;
}

.SelectedCategory:hover .SelectedCategory-icon {
    color: #E86029;
}

.SelectedCategory:hover .SelectedCategory-text {
    color: #E86029;
}

.SelectedCategory .AllproductCategory-icon {
    color: #E86029 !important;
    font-size: 14px;
}

.SelectedCategory .AllproductCategory-text {
    color: #E86029 !important;
    margin: 0;
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
}

.AllproductCategory-icon {
    color: #666;
    font-size: 14px;
}

.AllproductCategory-text {
    color: #666;
    margin: 0;
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
}

.subcategories {
    position: absolute;
    top: 0;
    left: 110%;
    background: white;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 200px;
    margin-right: 10px;
    border-radius: 5px;
}

.subAllproductCategory-text {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
    font-family: "Kanit";
    font-weight: 500;
}

.subAllproductCategory-text:hover {
    color: #E86029;
}

.more-AllproductCategory-container {
    cursor: pointer;
}

.more-AllproductCategory-container .more-AllproductCategory-icon {
    color: #E86029;
}

.more-AllproductCategory-container .more-AllproductCategory-text {
    color: #E86029;
    margin: 0;
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
}

.menu-icon {
    display: none;
}

.AllproductCategory-main {
    border-bottom: 1px solid #555;
    padding-bottom: 30px;
}

.allproductcategory-price-container {
    margin-top: 30px;
    border-bottom: 1px solid #555;
    padding-bottom: 30px;
}

.reset-and-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reset-and-text p {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
    margin: 0;
}

.reset-btn {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
    border: 0;
    background: #E86029;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}

.price-filter-input {
    width: 100%;
    border: 0;
    padding: 10px;
    background: #f6f7fa;
    box-shadow: inset 0 0 0 0 var(--component-color-lines-borders, var(--color-lines-borders));
    transition: box-shadow var(--duration-medium) ease, background var(--duration-medium) ease;
    color: #E86029;
    outline: none;
    margin-top: 10px;
    font-family: "Kanit";
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
}

@media screen and (max-width: 800px) {
    .AllproductCategory-main {
        display: none;
    }

    .AllproductCategory-container {
        width: 100%;
        display: flex;
        gap: 5px;
        align-items: center;
        position: unset;
        flex-wrap: wrap;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .AllproductCategory-container h2 {
        margin: 0;
    }

    .allproductcategory-price-container {
        width: 100%;
    }
}