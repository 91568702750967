.store-intgrate-maindiv {
    background-color: #F7F8FA;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 40px; */
    /* margin-bottom: 40px; */
    flex-direction: column;
}

.store-heading-container {
    background-color: #e86029;
    /* width: 57%; */
    padding: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.store-heading-container h3 {
    color: #fff;
    font-family: "Kanit";
    font-size: 26px;
    font-weight: 700;
}

.store-heading-container h6 {
    color: #fff;
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 500;
}

.store-intgrate-div {
    background-color: white;
    padding: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.store-intgrate-div input {
    background-color: white;
    border: 1px solid gray;
    width: 48%;
    font-size: 18px;
    border-radius: 8px;
    padding: 10px;
    font-weight: 600;
    color: black;
    margin-bottom: 20px;
    /* padding: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 20px; */
}

.store-intgrate {
    width: 60%;
    border-radius: 10px;
    margin-top: 40px;
}

.store-intgrate-div h6 {
    font-family: "Kanit";
}

.store-intgrate-div p {
    font-family: "Kanit";
}

@media screen and (max-width: 1024px) {
    .store-intgrate {
        width: 60%;
    }
}

@media screen and (max-width: 800px) {
    .store-intgrate {
        width: 70%;
    }
}

@media screen and (max-width: 600px) {
    .store-intgrate {
        width: 85%;
    }
    .store-intgrate-div input {
        width: 100%;
    }
    .store-intgrate-div div {
        width: 100% !important;
    }
}