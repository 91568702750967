.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #1B3356;
    overflow-x: hidden; /* Ensure no horizontal overflow */
    overflow-y: hidden; /* Ensure no vertical overflow */
    transition: width 0.5s;
    padding-top: 10px !important;
}

.sidebar.open {
    width: 250px;
}

.sidebar .sidebar-text {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    display: block;
    transition: color 0.3s, opacity 0.3s, transform 0.3s;
    font-size: 14px;
    font-family: "Kanit";
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    opacity: 0;
    transform: translateX(-100%) !important;
    visibility: hidden;
    border-top: 1px solid #fff;
}
.sidebar-history-heading {
    padding: 8px 8px 8px 12px;
    text-decoration: none;
    display: block;
    transition: color 0.3s, opacity 0.3s, transform 0.3s;
    font-size: 18px;
    font-family: "Kanit";
    color: #fff;
    letter-spacing: 2px;
    font-weight: 700;
    cursor: pointer;
    opacity: 0;
    transform: translateX(-100%) !important;
    visibility: hidden;
    margin-top: 20px;
    /* border-top: 1px solid #fff; */
}

.sidebar.open .sidebar-text {
    opacity: 1;
    transform: translateX(0) !important;
    visibility: visible;
}
.sidebar.open .sidebar-history-heading {
    opacity: 1;
    transform: translateX(0) !important;
    visibility: visible;
}

.sidebar .sidebar-text:hover {
    color: #c77500;
}

.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 20px !important;
    /* margin-left: 50px; */
}

.nav-menu {
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: opacity 0.5s;
    opacity: 0;
    pointer-events: none;
}

.overlay.active {
    opacity: 1;
    pointer-events: auto;
}

@media screen and (max-height: 450px) {
    .sidebar {
        padding-top: 15px;
    }
}
