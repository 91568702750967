a {
    text-decoration: none !important;
}

.border {
    border: 3px solid silver;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.border:hover {
    border: 3px solid #000;
}

.inp-border {
    border: none;
    outline: none;
}

.User {
    cursor: pointer;
}

.what-do {
    margin-top: 7%;
    margin-bottom: 7%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.what_do {
    margin-top: 8%;
}

.who_are {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 400;
}

#h1 {
    font-size: 30px;
    font-family: "Kanit";
    color: #222;
    font-weight: 600;
}

.btn-do {
    width: 180px;
    height: 50px;
    color: white !important;
    background-color: #E86029;
    border: 1px solid #E86029;
    border-radius: 10px;
    text-decoration: none;
    font-family: "Kanit";
}

#btn {
    color: white;
}

.who-are {
    margin-top: 40px !important;
    /* margin-bottom: 7%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
}

.who_are {
    width: 49%;
    /* margin-top: 9%; */
}

.make-uniq {
    background-color: #f6f7fa;
    margin-bottom: 30px;
    margin-top: 30px;
    padding-top: 60px;
    padding-bottom: 60px;
    /* width: 85%; */
    /* margin: 0 auto; */
}

.make_uniq {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 85%;
    margin: 0 auto;
    padding-top: 30px;
}

.unique-item {
    width: 30%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dropshipping {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.btn-drop {
    width: 169px;
    height: 50px;
    background-color: #d4f218;
    border: 1px solid #d4f218;
    border-radius: 10px;
}

.btn-warehouse {
    width: 169px;
    height: 50px;
    background-color: #0077fc;
    border: 1px solid #0077fc;
    border-radius: 10px;
    margin-left: 2%;
}

#btn2 {
    color: black;
}

#btn3 {
    color: white;
}

.Milestones {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 5%;
}

.Delivery-ratio {
    color: #E86029;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
}

#zam-resllers {
    color: #E86029;
    font: 20px "Avenir-next", sans-serif;
    margin-top: 1%;
}

#resl-name {
    color: #E86029;
    font: 16px "Avenir-next", sans-serif;
}

.d-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 5%;
}

.del-partner {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5%;
}

.layout_padding {
    padding: 70px 0;
}

.layout_padding2 {
    padding: 75px 0;
}

.layout_padding2-top {
    padding-top: 75px;
}

.layout_padding2-bottom {
    padding-bottom: 75px;
}

.layout_padding-top {
    padding-top: 90px;
}

.layout_padding-bottom {
    padding-bottom: 90px;
}

h1,
h2 {
    font-family: "Playfair Display", serif;
}

.heading_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.heading_container h2 {
    position: relative;
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: "Kanit";
}

.heading_container h2::after {
    content: "";
    display: block;
    width: 90px;
    height: 5px;
    background: #E86029;
    margin: 10px auto 10px;
}

.heading_container h2 span {
    color: #E86029;
}

.heading_container p {
    margin-top: 10px;
    margin-bottom: 0;
}

.heading_container.heading_center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

a,
a:hover,
a:focus {
    text-decoration: none;
}

a:hover,
a:focus {
    color: initial;
}

.btn,
.btn:focus {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*header section*/
.hero_area {
    position: relative;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sub_page .hero_area {
    min-height: auto;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

.header_section {
    padding: 15px 0;
}

.header_section .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
}

.navbar-brand {
    font-family: "Playfair Display", serif;
}

.navbar-brand span {
    font-weight: bold;
    font-size: 32px;
    color: #000000;
}

.custom_nav-container {
    padding: 0;
}

.custom_nav-container .navbar-nav {
    margin-left: auto;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 5px 20px;
    color: #131313;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 700;
}

.custom_nav-container .navbar-nav .nav-item .nav-link svg {
    width: 17px;
    height: auto;
    fill: #000000;
    margin-bottom: 2px;
}

.custom_nav-container .navbar-nav .nav-item:hover .nav-link,
.custom_nav-container .navbar-nav .nav-item.active .nav-link {
    color: #E86029;
}

.custom_nav-container .navbar-nav .nav-item:hover .nav-link svg,
.custom_nav-container .navbar-nav .nav-item.active .nav-link svg {
    fill: #E86029;
}

.custom_nav-container .nav_search-btn {
    width: 35px;
    height: 35px;
    padding: 0;
    border: none;
    color: #000000;
}

.custom_nav-container .nav_search-btn:hover {
    color: #E86029;
}

.custom_nav-container .navbar-toggler {
    outline: none;
}

.custom_nav-container .navbar-toggler {
    padding: 0;
    width: 37px;
    height: 42px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: #000000;
    margin: 7px 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    border-radius: 5px;
    transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::before,
.custom_nav-container .navbar-toggler span::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    top: -10px;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::after {
    top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before,
.custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 0;
}

/*end header section*/
/* slider section */
.slider_section {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 90px 0 75px 0;
}

.slider_section .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.slider_section #customCarousel1 {
    width: 100%;
    position: unset;
}

.slider_section .detail-box h1 {
    font-size: 4.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #E86029;
    line-height: 70px;
}

.slider_section .detail-box h1 span {
    color: #E86029;
}

.slider_section .detail-box p {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 1.5rem;
}

.slider_section .detail-box a {
    display: inline-block;
    padding: 12px 45px;
    background-color: #E86029;
    border: 1px solid #E86029;
    color: #ffffff;
    border-radius: 50px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-top: 10px;
    font-weight: 700;
}

.slider_section .detail-box a:hover {
    background-color: transparent;
    color: #E86029;
}

.slider_section .img-box img {
    width: 100%;
}

.slider_section .carousel-indicators {
    position: unset;
    margin: 0;
    margin-top: 45px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.slider_section .carousel-indicators li {
    background-color: #ffffff;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    opacity: 1;
}

.slider_section .carousel-indicators li.active {
    width: 20px;
    height: 20px;
    background-color: #E86029;
}

.slider_bg_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.slider_bg_box img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top right;
    object-position: top right;
}

.product_section .heading_container {
    margin-bottom: 20px;
}

.product_section .box {
    position: relative;
    margin-top: 25px;
    padding: 35px 35px;
    background-color: #f7f8f9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow: hidden;
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.2);
    border: solid #fff 10px;
}

.product_section .box .img-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 215px;
}

.product_section .box .img-box img {
    max-width: 100%;
    max-height: 160px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.product_section .box .detail-box {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product_section .box .detail-box h5 {
    font-size: 18px;
    margin-top: 10px;
    font-family: "Kanit";
}

.product_section .box .detail-box h6 {
    margin-top: 10px;
    color: #E86029;
    font-weight: 600;
    font-family: "Kanit";
}

.product_section .box:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.product_section .box:hover .option_container {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.arrival_section {
    background-color: #d4ced0;
}

.product_section .option_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}

.product_section .options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product_section .options a {
    display: inline-block;
    padding: 8px 15px;
    border-radius: 30px;
    width: 165px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin: 5px 0;
}

.product_section .options .option1 {
    background-color: #E86029;
    border: 1px solid #E86029;
    color: #ffffff;
}

.product_section .options .option1:hover {
    background-color: transparent;
    color: #E86029;
}

.product_section .options .option2 {
    background-color: #000000;
    border: 1px solid #000000;
    color: #ffffff;
}

.product_section .options .option2:hover {
    background-color: transparent;
    color: #000000;
}

.product_section .btn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 45px;
    border-radius: 12px;
}

.product_section .btn-box a {
    display: inline-block;
    padding: 10px 40px;
    background-color: #E86029;
    border: 1px solid #E86029;
    color: #ffffff;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    /* border-radius: 12px; */
}

.product_section .btn-box a:hover {
    background-color: transparent;
    color: #E86029;
    border-radius: 12px;
}

.arrival_section .heading_container {
    color: #E86029;
    margin-bottom: 10px;
}

.arrival_section .box {
    padding: 120px 45px;
    position: relative;
}

.arrival_section .arrival_bg_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.arrival_section .arrival_bg_box img {
    width: 50%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top left;
    object-position: top left;
}

.arrival_section a {
    display: inline-block;
    padding: 10px 45px;
    background-color: #E86029;
    border: 1px solid #E86029;
    color: #ffffff;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.arrival_section a:hover {
    background-color: transparent;
    color: #E86029;
}

.why_section {
    margin-top: 40px;
}

.why_section .box {
    text-align: center;
    margin-top: 45px;
    background-color: #E86029;
    padding: 45px 50px;
    border-radius: 5px;
    color: #ffffff;
}

.detail-box h5 {
    font-size: 21px;
    font-weight: 700;
    font-family: "Kanit";
}

.remove_line_bt h2::after {
    display: none;
}

.why_section .box .img-box {
    margin-bottom: 15px;
}

.why_section .box .img-box svg {
    width: 55px;
    height: auto;
    fill: #ffffff;
}

.subscribe_section {
    text-align: center;
}

.subscribe_section .box {
    background-color: #d4ced0;
    padding: 75px 45px;
}

.subscribe_section .subscribe_form .heading_container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #E86029;
}

.subscribe_section .subscribe_form .heading_container h2 {
    padding: 0 25px;
}

.subscribe_section .subscribe_form form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.subscribe_section .subscribe_form form input {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 90px;
    outline: none;
    padding: 5px 25px;
    background-color: #ffffff;
    margin-top: 15px;
    text-align: left;
}

.subscribe_section .subscribe_form form input::-webkit-input-placeholder {
    color: #171620;
}

.subscribe_section .subscribe_form form input:-ms-input-placeholder {
    color: #171620;
}

.subscribe_section .subscribe_form form input::-ms-input-placeholder {
    color: #171620;
}

.subscribe_section .subscribe_form form input::placeholder {
    color: #171620;
}

.subscribe_section .subscribe_form form button {
    display: inline-block;
    padding: 10px 45px;
    background-color: #E86029;
    border: 1px solid #E86029;
    color: #ffffff;
    border-radius: 35px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-transform: uppercase;
    margin-top: 35px;
    font-weight: 600;
    width: 100%;
    max-width: 285px;
}

.subscribe_section .subscribe_form form button:hover {
    background-color: transparent;
    color: #E86029;
}

.client_section .box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin-top: 55px;
}

.client_section .box .img_container {
    width: auto;
    height: auto;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
}

.client_section .box .img_container .img-box {
    width: auto;
    height: auto;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ffffff;
    margin-right: -1px;
}

.client_section .box .img_container .img-box .img_box-inner {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}

.client_section .box .img_container .img-box .img_box-inner img {
    width: 100%;
}

.detail-box p {
    font-family: "Kanit";
}

.client_section .box .detail-box {
    margin-top: 25px;
}

.client_section .box .detail-box h5 {
    font-size: 20px;
    font-weight: 600;
}

.client_section .box .detail-box h6 {
    font-size: 15px;
    color: #999998;
}

.client_section .carousel-control-prev,
.client_section .carousel-control-next {
    top: 43%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
    width: auto;
    margin: 0 5px;
    width: 50px;
    height: 50px;
    opacity: 1;
    color: #ffffff;
    background-color: #E86029;
    border-radius: 100%;
}

.client_section .carousel-control-prev:hover,
.client_section .carousel-control-next:hover {
    background-color: #212121;
}

.client_section .carousel-control-prev {
    left: 25%;
}

.client_section .carousel-control-next {
    right: 25%;
}

/* footer section*/
.footer_section {
    background-color: #E86029;
    color: #ffffff;
    padding-top: 70px;
}

.footer_section {
    color: #ffffff;
    text-align: left;
}

.footer_section h4 {
    font-size: 28px;
}

.footer_section h4,
.footer_section .footer-logo {
    font-weight: 600;
    margin-bottom: 20px;
    font-family: "Playfair Display", serif;
    text-align: left;
}

.footer_section .footer-col {
    margin-bottom: 30px;
}

.footer_section .footer_contact .contact_link_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.footer_section .footer_contact .contact_link_box a {
    margin: 10px 0;
    color: #ffffff;
}

.footer_section .footer_contact .contact_link_box a i {
    margin-right: 5px;
}

.footer_section .footer_contact .contact_link_box a:hover {
    color: #E86029;
}

.footer_section .footer-logo {
    display: block;
    font-weight: bold;
    font-size: 32px;
    color: #ffffff;
}

.footer_section .footer_social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
    -ms-flex-pack: center;
    justify-content: left;
    margin-top: 25px;
    margin-bottom: 10px;
}

.footer_section .footer_social a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #E86029;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #ffffff;
    border-radius: 100%;
    margin: 0 2.5px;
    font-size: 18px;
}

.footer_section .footer_social a:hover {
    color: #E86029;
}

.footer_section .map_container {
    width: 100%;
    height: 175px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.footer_section .map_container .map {
    height: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.footer_section .map_container .map #googleMap {
    height: 100%;
}

.footer_section .footer-info {
    text-align: center;
    margin-top: 25px;
}

.footer_section .footer-info p {
    color: #ffffff;
    margin: 0;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer_section .footer-info p a {
    color: inherit;
}

/*# sourceMappingURL=style.css.map */

.heading_container.heading_center h3 {
    position: relative;
    margin-bottom: 15px;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: "Playfair Display", serif;
}

li.nav-item ul {
    background: #fff;
    border: solid #000 2px;
    border-radius: 0;
    padding: 10px 20px;
    margin-left: 10px;
    margin-top: 10px;
    box-shadow: 4px 4px 0px 0 #000;
    width: 190px;
}

li.nav-item ul li {
    float: left;
    width: 100%;
    margin: 6px 0;
}

li.nav-item ul li a {
    color: #212529;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-top: 10px;
    transition: ease all 0.5s;
}

li.nav-item ul li a:hover,
li.nav-item ul li a:focus {
    color: #E86029;
}

.inner_page_head {
    background: #E86029;
    text-align: center;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 40px;
}

.inner_page_head h3 {
    font-size: 42px;
    font-weight: 800;
}

/** footer **/

footer {
    background: #f8f8f8;
    padding: 90px 0 70px;
}

.logo_footer {
    margin-bottom: 30px;
}

.information_f p {
    margin-bottom: 1.2rem;
    line-height: 25px;
    font-size: 15px;
}

.widget_menu h3 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    margin-bottom: 25px;
}

.widget_menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.widget_menu ul li {
    float: left;
    width: 100%;
}

.widget_menu ul li a {
    font-size: 15px;
    color: #000;
    margin-bottom: 0;
    float: left;
    width: 100%;
    margin-top: 5px;
}

.field {
    display: flex;
}

.widget_menu {
    float: left;
    width: 100%;
}

div.form_sub .field input[type="email"],
div.form_sub .field input[type="email"]:hover,
div.form_sub .field input[type="email"]:focus {
    padding: 10px 110px 10px 15px;
    border-radius: 0;
    border: solid #ccc 1px;
    font-size: 14px;
    position: relative;
    box-shadow: none;
    width: 100%;
    height: 48px;
}

.form_sub {
    position: relative;
}

.form_sub input[type="email"]+input[type="submit"] {
    position: absolute;
    right: 0;
    background: #E86029;
    color: #fff;
    border: none;
    top: 0px;
    font-size: 14px;
    height: 48px;
    font-weight: 600;
    padding: 0 15px;
}

form input {
    background: #fff;
    border: solid #ccc 1px;
    padding: 15px 15px;
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
    text-transform: capitalize;
    line-height: normal;
}

form textarea {
    background: #fff;
    border: solid #ccc 1px;
    padding: 15px 15px;
    width: 100%;
    min-height: 150px;
    font-size: 14px;
    margin-bottom: 20px;
    text-transform: capitalize;
    line-height: normal;
}

form input[type="submit"] {
    border: none;
    padding: 15px 45px;
    width: auto;
    font-size: 16px;
    text-transform: capitalize;
    line-height: normal;
    margin: 0 auto;
    display: flex;
    background: #333;
    color: #fff;
    font-weight: 600;
    transition: ease all 0.1s;
}

form input[type="submit"]:hover,
form input[type="submit"]:focus {
    background: #E86029;
    color: #fff;
}

/** cpy **/

.cpy_ {
    background: #222;
    text-align: center;
    padding: 20px 0;
}

.cpy_ p {
    margin: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}

.cpy_ p a {
    margin: 0;
    color: #E86029;
    font-size: 15px;
    font-weight: 400;
}

.who-are-image {
    width: 50%;
}

@media (max-width: 1024px) {
    .unique-item {
        width: 40%;
        text-align: center;
        margin-top: 20px
    }
}

@media (max-width: 800px) {
    .who-are {
        flex-direction: column;
    }

    .who_are {
        width: 100%;
    }

    .who-are-image {
        width: 100%;
        margin-top: 30px;
    }
}

@media (max-width: 576px) {
    .heading_container h2 {
        font-size: 2.2rem;
    }
    .unique-item {
        width: 100%;
        text-align: center;
        margin-top: 20px
    }
}

@media (max-width: 480px) {
    .unique-item h5 {
        font-size: 14px !important;
        font-size: 600;
    }

    .heading_container h2 {
        font-size: 2rem;
    }

    .carousel_btn_box {
        display: flex;
        justify-content: center;
        margin-top: 25px;
    }

    .client_section .carousel-control-prev,
    .client_section .carousel-control-next {
        position: unset;
        transform: none;
    }
}

@media (max-width: 420px) {}

@media (max-width: 376px) {
    .slider_section .detail-box h1 {
        font-size: 3rem;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

/* http://kute-themes.com/html/boutique/html/index.html
https://template.hasthemes.com/noraure/noraure/index.html
https://preview.hasthemes.com/xoss-preview/xoss/index.html
 */