.nav-container {
    width: 85%;
    margin: 0 auto;
    padding: 30px 0;
}

.nav-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-input-dropdown {
    display: flex;
    align-items: center;
    /* border: 1px solid #fff; */
    width: 40%;
    background-color: #fff;
    padding-left: 10px;
    border-radius: 12px;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

.nav-input-dropdown input {
    border: none;
    outline: none;
    font-size: 13px;
    font-family: "Kanit";
    width: 60.2%;
    margin-right: 10px;
    background: transparent;
}

.nav-input-dropdown button {
    border: none;
    outline: none;
    font-family: "Kanit";
    background-color: #E86029;
    padding: 10px;
    margin: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.nav-wishlist-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
}

.nav-wishlist-container .nav-heart-container {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
}

.nav-wishlist-container:hover .nav-heart {
    color: #E86029;
}

.nav-wishlist-container:hover .nav-wishlist-text {
    color: #E86029;
}

.nav-wishlist-text {
    color: #fff;
    font-family: "Kanit";
    font-size: 13px;
    margin: 0;
    margin-left: 12px;
}

.nav-login-text {
    color: #fff;
    font-family: "Kanit";
    font-size: 16px;
    margin: 0;
    /* margin-left: 12px; */
}

.nav-login-and-wishlist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
}

.nav-links-container {
    background-color: #B43F3F;
    width: 100%;
}

.nav-link {
    display: flex !important;
    list-style: none;
    width: 35%;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}

.nav-link li {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    font-family: "Kanit";
    cursor: pointer;
    position: relative;
}

.nav-link li.active::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: white;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.nav-cart-container {
    padding: 15px 10px;
    /* background-color: #E86029; */
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.nav-cart-container p {
    margin: 0;
    font-size: 12px;
    color: white;
    font-family: "Kanit";
}

.nav-history-container {
    padding: 15px 10px;
    /* background-color: #E86029; */
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.nav-history-container p {
    margin: 0;
    font-size: 12px;
    color: white;
    font-family: "Kanit";
}

.nav-menu {
    display: none !important;
}

.order-cart-container {
    display: flex;

}

.suggestions-dropdown {
    width: 100%;
    height: 150px;
    padding: 10px;
    overflow-y: scroll;
    position: absolute;
    z-index: 9999;
    background: #fff;
    top: 50px;
    left: -1px;
    border-radius: 10px;
}

.suggestions-product {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    cursor: pointer;

}

.suggestions-product img {
    width: 40px;
    height: 40px;
    margin: 0;
}
.suggestion-item{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}
@media screen and (max-width: 1280px) {
    .nav-wishlist-text {
        display: none !important;
    }

    .nav-input-dropdown input {
        width: 60%;
    }
}

@media screen and (max-width: 1024px) {
    .nav-wishlist-text {
        display: none !important;
    }

    .nav-input-dropdown input {
        width: 50%;
    }

    .nav-link {
        width: 45%;
    }
}

@media screen and (max-width: 800px) {
    .nav-input-dropdown {
        display: none;
    }

    /* .nav-wishlist-container {
        display: none;
    } */

    .nav-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nav-logo {
        width: 40%;
    }

    .nav-link {
        display: none !important;
    }

    .nav-cart-container p {
        display: none !important;
    }

    .nav-history-container {
        display: none !important;
    }

    .nav-menu {
        display: flex !important;
        width: 20px;
        cursor: pointer;
    }
}

@media screen and (max-width: 600px) {
    .nav-logo {
        width: 50%;
    }
}

@media screen and (max-width: 400px) {
    .nav-logo {
        width: 50%;
    }
}