.App {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.balloon {
    position: absolute;
    width: 200px;
    height: 250px;
    background: url('../../images/poper-removebg-preview.png') no-repeat center center;
    background-size: cover;
    animation: float 5s infinite ease-in-out;
    z-index: 2;
}

.balloon.left {
    left: 10%;
    bottom: 20%;
    animation-duration: 6s;
}

.balloon.right {
    right: 10%;
    bottom: 20%;
    animation-duration: 6s;
}

@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
}

#rewardCard {
    width: 320px;
    height: 240px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 20px;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#rewardCard:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}

#rewardCard h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-family: "Kanit";
}

#rewardCard p {
    font-size: 18px;
    color: #555;
    margin-top: 10px;
    font-family: "Kanit";
}

#rewardMessage {
    font-size: 22px;
    color: #333;
    margin: 20px 0;
    font-weight: bold;
}

#claimButton {
    font-family: "Kanit";
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    background-color: #E86029;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#claimButton:hover {
    background-color: #E86029;
}

.confetti {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;
}

.confetti-piece {
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(255, 87, 34, 0.8);
    border-radius: 50%;
    animation: confetti 1s forwards;
}

@keyframes confetti {
    0% {
        transform: translateY(-100vh) rotate(0deg);
    }

    100% {
        transform: translateY(100vh) rotate(360deg);
    }
}
@media screen and (max-width: 800px) {
    .balloon.left {
       display: none !important;
    }
    
    .balloon.right {
        display: none !important;
    }
}