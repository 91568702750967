.trending-container {
    width: 72%;
}

.trending-main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-color: #ebebeb;
    border-bottom-style: solid;
}

.trending-main h2 {
    font-size: 20px !important;
    font-family: "Kanit";
    font-weight: 600;
    color: black;
    border-bottom-width: 2px;
    border-bottom-color: #E86029;
    border-bottom-style: solid;
    width: 20%;
    padding-bottom: 10px;
    margin: 0;
}

.trending-item-list {
    display: flex;
}

.trending-item-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    gap: 15px;
    overflow-x: scroll;
    white-space: nowrap;

}

.trending-item-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.trending-item-list p {
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 500;
    /* padding: ; */
    padding-bottom: 10px;
    /* margin-right: 10px !important; */
    color: #999;
    margin: 0;
    cursor: pointer;
}

.trending-item-list p:hover {
    cursor: pointer;
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 500;
    /* padding: ; */
    padding-bottom: 10px;
    /* margin-right: 10px !important; */
    color: #E86029;
    margin: 0;
    border-bottom-width: 2px;
    border-bottom-color: #E86029;
    border-bottom-style: solid;
}

.trending-item-list .active {
    cursor: pointer;
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 500;
    /* padding: ; */
    padding-bottom: 10px;
    /* margin-right: 10px !important; */
    color: #E86029;
    margin: 0;
    border-bottom-width: 2px;
    border-bottom-color: #E86029;
    border-bottom-style: solid;
}

.trending-arrow-container {
    display: flex;
    gap: 15px;
    padding-bottom: 10px;
}

.trending-arrow {
    border-radius: 50%;
    /* padding:4px 8px; */
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6666663d;
    cursor: pointer;
}

.trending-item-container {
    width: 100%;
    border: 1px solid #ebebeb;
    padding: 15px;
    margin-top: 10px;
    border-top: none !important;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    flex-wrap: wrap;
}

.trending-item {
    width: 49%;
    border-right-color: #ebebeb;
    border-right-style: solid;
    border-right-width: 2px;
    display: flex;
    padding-top: 30px;
    /* cursor: pointer; */
}

.trending-item-image {
    transition: all 0.5s ease-in-out;
    width: 120px;
    height: 120px;
    cursor: pointer;
}

.trending-items-content {
    margin-left: 15px;
}

.trending-items-content .trending-items-name {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
    color: #222;
    margin: 0;
    margin-bottom: 8px;
}

.trending-items-content .trending-items-price {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 700;
    color: #222;
    margin: 0;
    margin-top: 8px;
}

.trending-addcart {
    background: #666666;
    border-radius: 3px;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.trending-addcart:hover {
    background-color: #E86029;
}

.trending-addcart .trending-icon {
    color: #fff;
    font-size: 12px;
}

.trending-addcart p {
    color: #fff;
    font-size: 12px;
    font-family: "Kanit";
    margin: 0;
    margin-left: 8px;

}

.trending-item-dropdown {
    display: none;
}

@media screen and (max-width: 1280px) {
    .trending-main h2 {
        width: 22%;
    }
}

@media screen and (max-width: 1024px) {
    .trending-item-list {
        width: 50%;
    }

    .trending-main h2 {
        width: 24%;
    }

    .trending-container {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .trending-arrow-container {
        display: none;
    }

    .trending-item-image {
        transition: all 0.5s ease-in-out;
        width: 100px;
        height: 100px;
    }

    .trending-item-container {
        padding: 20px !important;
    }

    .trending-item-list {
        display: none !important;
    }

    .trending-item {
        border: none !important;
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .trending-item-dropdown {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .trending-item-container {
        flex-direction: column;
    }

    .trending-item {
        border: none !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .trending-items-content {
        margin-left: 30px;
    }

    .trending-main h2 {
        width: 38%;
    }

}

@media screen and (max-width: 400px) {
    .trending-main h2 {
        width: 50%;
    }
}