.reward-container {
    width: 85%;
    margin: 30px auto;
}

.reward-main-heading {
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 3s linear infinite;
    animation: shine 3s linear infinite;
    background: linear-gradient(90deg, #E86029 5%, #000 46%, #E86029 100%);
    -webkit-background-clip: text;
    font-family: "Kanit", sans-serif;
    font-size: 30px;
    letter-spacing: .1rem;
    font-weight: 500;
    text-align: center;
}

.reward-orders-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;
}

.reward-blance-info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.reward-blance-info-container ul {
    list-style: none;
    display: flex;
    gap: 10px;
    font-family: "Kanit";
    margin: 0 !important;
    padding: 0;
}

.reward-balance-container {
    background-color: #E86029;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 8px;
    gap: 10px;
    cursor: pointer;
}

.reward-balance-container p {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
    margin: 0px;
    color: #fff;
}

.reward-order {
    background: #fff;
    border-radius: 8px;
    width: 30%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 30px;
    height: 8rem;
    /* margin: 10px 10px; */
}

.reward-order-image-text {
    display: flex;
    align-items: center;
}

.reward-order-image-container {
    background: #e8602952;
    padding: 10px;
    border-radius: 50%;

}

.reward-order-image-text p {
    font-size: 18px;
    font-family: "Kanit";
    font-weight: 500;
    color: #555;
    margin-left: 10px !important;
    margin: 0px;
}

.reward-number {
    font-size: 22px;
    font-family: "Kanit";
    font-weight: 500;
    margin-top: 20px;
    color: #000 !important;
}

.reward-headline {
    font-size: 14px;
    font-size: "Kanit";
    font-weight: 500;
    margin: 20px 0;
}

.reward-chart-container {
    background: #fff;
    border-radius: 8px;
    width: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 250px;
}

.reward-chart-container p {
    font-family: "Kanit";
    font-weight: 500;
    font-size: 18px;
}

.reward-claim-btn {
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .1rem;
    border: none;
    background: #E86029;
    color: #fff;
    padding: 8px 20px;
    border-radius: 8px;
    margin-top: 20px;
}

.balance-withdraw-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.withdarwal-modal {
    width: 320px;
    height: 320px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 20px;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
}

.reward-account-name {
    border: 1px solid #555;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
    width: 100%;
    outline: none;
    margin-top: 15px;
}

.reward-submit-btn {
    border: none;
    width: 100%;
    padding: 10px;
    background: #E86029;
    color: #fff;
    font-size: 14px;
    font-family: "Kanit";
    border-radius: 12px;
    margin-top: 15px;
}

@keyframes shine {
    0% {
        background-position: 0%;
    }

    60% {
        background-position: 600px;
    }

    100% {
        background-position: 600px;
    }
}

@media screen and (max-width: 800px) {
    .reward-blance-info-container {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .reward-balance-container {
        margin-top: 20px;
    }

    .reward-order {
        padding: 15px;
    }

    .reward-chart-container {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .reward-blance-info-container ul {
        flex-direction: column;
    }

    .reward-order {
        width: 100%;
        margin-top: 20px;
    }
}
@media screen and (max-width: 400px) {
    .reward-balance-container p {
        font-size: 12px;
        font-family: "Kanit";
        font-weight: 500;
        margin: 0px;
        color: #fff;
    }
}