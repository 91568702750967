* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Kanit';
  src: url('./fonts/Kanit-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  /* background: #F5D7DB !important; */
}

.category-slider {
  width: 85%;
  border: 1px solid #ebebeb;
  margin-top: 20px !important;
  padding: 20px 20px 15px 15px;
  margin: 20px auto 45px auto;
  display: flex;
  border-radius: 3px;
  justify-content: space-between;
}

.tags-trending {
  width: 85%;
  /* border: 1px solid #ebebeb; */
  margin-top: 20px !important;
  /* padding: 20px 20px 15px 15px; */
  margin: 20px auto 45px auto;
  display: flex;
  justify-content: space-between;
  /* border-radius: 3 */
}

@media screen and (max-width: 800px) {
  .category-slider {
    flex-direction: column;
    /* align-items: center; */
    padding-bottom: 20px !important;
  }

  .tags-trending {
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) {


  .tags-trending {
    flex-direction: column;
  }
}

.footer_section {
  background-color: #E86029;
  color: #ffffff;
  padding-top: 70px;
}

.footer_section {
  color: #ffffff;
  text-align: left;
}

.footer_section h4 {
  font-size: 28px;
}

.footer_section h4,
.footer_section .footer-logo {
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Kanit";
  text-align: left;
}

.footer_section .footer-col {
  margin-bottom: 30px;
}

.footer_section .footer_contact .contact_link_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer_section .footer_contact .contact_link_box a {
  margin: 10px 0;
  color: #ffffff;
  text-decoration: none;
  font-family: "Kanit";
}

.footer_section .footer_contact .contact_link_box a i {
  margin-right: 5px;
}

.footer_section .footer_contact .contact_link_box a:hover {
  color: #1e35ac;
}

.footer_section .footer-logo {
  display: block;
  font-weight: bold;
  font-size: 32px;
  color: #ffffff;
  font-family: "Kanit";
}

.footer_section .footer_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
  -ms-flex-pack: center;
  justify-content: left;
  margin-top: 25px;
  margin-bottom: 10px;
}

.footer_section .footer_social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #E86029;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #ffffff;
  border-radius: 100%;
  margin: 0 2.5px;
  font-size: 18px;
}

.footer_section .footer_social a:hover {
  color: #1e35ac;
}

.footer_section .map_container {
  width: 100%;
  height: 175px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.footer_section .map_container .map {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.footer_section .map_container .map #googleMap {
  height: 100%;
}

.footer_section .footer-info {
  text-align: center;
  margin-top: 25px;
}

.footer_section .footer-info p {
  color: #ffffff;
  margin: 0;
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer_section .footer-info p a {
  color: inherit;
}

/*# sourceMappingURL=style.css.map */

.heading_container.heading_center h3 {
  position: relative;
  margin-bottom: 15px;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: "Playfair Display", serif;
}

li.nav-item ul {
  background: #fff;
  border: solid #000 2px;
  border-radius: 0;
  padding: 10px 20px;
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: 4px 4px 0px 0 #000;
  width: 190px;
}

li.nav-item ul li {
  float: left;
  width: 100%;
  margin: 6px 0;
}

li.nav-item ul li a {
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin-top: 10px;
  transition: ease all 0.5s;
}

li.nav-item ul li a:hover,
li.nav-item ul li a:focus {
  color: #1e35ac;
}

.inner_page_head {
  background: #1e35ac;
  text-align: center;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 40px;
}

.inner_page_head h3 {
  font-size: 42px;
  font-weight: 800;
}

/** footer **/

footer {
  background: #f8f8f8;
  padding: 90px 0 70px;
}

.logo_footer {
  margin-bottom: 30px;
}

.information_f p {
  margin-bottom: 1.2rem;
  line-height: 25px;
  font-size: 15px;
}

.widget_menu h3 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  margin-bottom: 25px;
}

.widget_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget_menu ul li {
  float: left;
  width: 100%;
}

.widget_menu ul li a {
  font-size: 15px;
  color: #000;
  margin-bottom: 0;
  float: left;
  width: 100%;
  margin-top: 5px;
}

.field {
  display: flex;
}

.widget_menu {
  float: left;
  width: 100%;
}

div.form_sub .field input[type="email"],
div.form_sub .field input[type="email"]:hover,
div.form_sub .field input[type="email"]:focus {
  padding: 10px 110px 10px 15px;
  border-radius: 0;
  border: solid #ccc 1px;
  font-size: 14px;
  position: relative;
  box-shadow: none;
  width: 100%;
  height: 48px;
}

.form_sub {
  position: relative;
}

.form_sub input[type="email"]+input[type="submit"] {
  position: absolute;
  right: 0;
  background: #1e35ac;
  color: #fff;
  border: none;
  top: 0px;
  font-size: 14px;
  height: 48px;
  font-weight: 600;
  padding: 0 15px;
}

form input {
  background: #fff;
  border: solid #ccc 1px;
  padding: 15px 15px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
  text-transform: capitalize;
  line-height: normal;
}

form textarea {
  background: #fff;
  border: solid #ccc 1px;
  padding: 15px 15px;
  width: 100%;
  min-height: 150px;
  font-size: 14px;
  margin-bottom: 20px;
  text-transform: capitalize;
  line-height: normal;
}

form input[type="submit"] {
  border: none;
  padding: 15px 45px;
  width: auto;
  font-size: 16px;
  text-transform: capitalize;
  line-height: normal;
  margin: 0 auto;
  display: flex;
  background: #333;
  color: #fff;
  font-weight: 600;
  transition: ease all 0.1s;
}

form input[type="submit"]:hover,
form input[type="submit"]:focus {
  background: #1e35ac;
  color: #fff;
}

/** cpy **/

.cpy_ {
  background: #222;
  text-align: center;
  padding: 20px 0;
}

.cpy_ p {
  margin: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.cpy_ p a {
  margin: 0;
  color: #1e35ac;
  font-size: 15px;
  font-weight: 400;
}

.orderapi-popup {
  background-color: #fff;
  border-radius: 8px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  flex-direction: column;
  width: 420px;
  padding: 20px;
  position: relative;
  font-family: "Kanit";
  font-size: 14px;
  font-weight: 500;
  /* text-align: center; */
}
.header-token{
  word-break: break-all;
  font-family: "Kanit";
  font-size: 14px;
  font-weight: 500;
}