.reachUs-button {
    padding: 1rem;
    background-color: #E86029;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.reachUs-button:hover {
    background-color: #1ac840;
}

.banner {
    text-align: center;
    background-color: #ffffff;
    margin: 0 auto;
}

.banner img {
    max-width: 100%;
    height: auto;
}

.contact-form {
    padding: 40px 0;
    margin: 0 10px;
}

.form-container {
    max-width: 50%;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: "Kanit";
    font-weight: 500;
    font-size: 28px;
}

.form-group {
    margin-bottom: 20px;
}

.form-container label {
    display: block;
    font-weight: bold;
    font-family: "Kanit";
}

.form-container input,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    resize: vertical;
}

.submit-button {
    padding: 10px 20px;
    background-color: #E86029;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    font-family: "Kanit";
}

.contact-info {
    text-align: center;
    padding: 50px 0;
    background-color: #f7f7f7;
}

.contact-info h2 {
    margin-bottom: 20px;
}

.hamburger {
    display: none;
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
    cursor: pointer;
}

.contact-welcome-container h3 {
    font-family: "Kanit";
    font-size: 22px;
    font-weight: 600;
}

.contact-welcome-container p {
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 500;
}

.contact-us-main-container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    margin-top: 20px;
    /* align-items: center; */
}

.contact-us-image {
    width: 50%;
    /* height: 100%; */
}

.plus-number {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #EFC1B8;
    color: #000;
    font-family: "Kanit";
    font-size: 20px;
    font-weight: 500;
    border-radius: 50%;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1024px) {
    .form-container {
        max-width: 50%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 800px) {
    .contact-us-main-container {
        flex-direction: column;
    }

    .contact-us-image {
        width: 100%;
        margin-top: 20px;
        /* height: 100%; */
    }

    .hamburger {
        display: flex;
    }

    .banner img {
        width: 100%;
    }

    .form-container {
        max-width: 100%;
        margin: 0 10px;
    }
}

@media only screen and (max-width: 480px) {
    .contact-info {
        padding: 20px 0;
    }

    .submit-button {
        width: 100%;
        padding: 15px;
    }

    .reachUs-button {
        width: 100%;
        padding: 15px;
    }
}