.all-products-main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 68%
}

.all-product-main {
    background: #fff;
    border-radius: 8px;
    width: 30%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    margin: 10px 10px;

}

.Allproduct-main-container {
    margin-top: 20px;
}

.all-product-main:hover {
    transform: scale(1.1);
}

.allproduct-main-heading {
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
    background: linear-gradient(90deg, #E86029 5%, #000 46%, #E86029 100%);
    -webkit-background-clip: text;
    font-family: "Kanit", sans-serif;
    font-size: 30px;
    letter-spacing: .1rem;
    font-weight: 500;
    /* text-align: center; */
    margin: 0;
}

.main-heading-sort-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: 0 auto
}

.allproduct-main-heading-container {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
    .all-product-main {
        background: #fff;
        border-radius: 8px;
        width: 45%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 10px;
        margin: 10px 10px;

    }
}

@media screen and (max-width: 800px) {
    .all-product-main {
        background: #fff;
        border-radius: 8px;
        width: 45%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 10px;
        margin: 10px 10px;

    }

    .all-products-main {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .all-product-main {
        background: #fff;
        border-radius: 8px;
        width: 100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 10px;
        margin: 10px 10px;

    }

    .all-products-main {
        width: 100%;
    }

    .allproduct-main-heading {
        font-size: 22px;
    }
    .allproduct-main-heading-container {
        align-items: flex-start;
        justify-content: flex-start;
    }
}