.services-maindiv {
    background-color: #F7F8FA;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 40px; */
    /* margin-bottom: 40px; */
    flex-direction: column;
}

.services-heading-container {
    background-color: #e86029;
    /* width: 57%; */
    padding: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.services-heading-container h3 {
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 3s linear infinite;
    animation: shine 3s linear infinite;
    background: linear-gradient(90deg, #fff 5%, #000 46%, #fff 100%);
    -webkit-background-clip: text;
    font-family: "Kanit", sans-serif;
    font-size: 30px;
    letter-spacing: .1rem;
    font-weight: 500;
    /* text-align: center; */
}

.services-heading-container h6 {
    color: #fff;
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 500;
}

.services-div {
    background-color: white;
    padding: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 20px;
}

.services {
    width: 40%;
    border-radius: 10px;
    margin-top: 40px;
}

.services-div h6 {
    font-family: "Kanit";
}
.services-div p {
    font-family: "Kanit";
}

@media screen and (max-width: 1024px) {
    .services {
        width: 60%;
    }
}

@media screen and (max-width: 800px) {
    .services {
        width: 70%;
    }
}

@media screen and (max-width: 600px) {
    .services {
        width: 85%;
    }
}