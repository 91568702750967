.product-detail-main-container {
    width: 85%;
    margin: 20px auto;
}

.product-detail {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.product-detail-images-container {
    width: 40%;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 20px;
    /* Adjust as needed */
    height: fit-content;
}

.mini-image-container {
    height: 300px;
    width: 20%;
    overflow-y: auto;
}

.mini-image-container img {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}
.mini-image-container iframe {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.product-detail-big-image {
    width: 78%;
    border: 1px solid #555;
    border-radius: 10px;
    padding: 10px;
}

.product-detail-big-image img {
    width: 100%;
    height: 300px;
    object-fit: contain;
}

.product-detialed {
    width: 58%;
}

.product-detail-sku {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500
}

.product-detail-name {
    font-size: 22px;
    font-family: "Kanit";
    font-weight: 700;
}

.product-detail-price {
    font-size: 18px;
    font-family: "Kanit";
    font-weight: 500;
}

.product-detail-download {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.product-detail-download img {
    width: 15px;
    height: 15px;
}

.product-detail-download p {
    margin: 0;
    font-size: 18px;
    font-family: "Kanit";
    font-weight: 500;
    color: #E86029;

}

.accordion {
    margin: 20px 0;
}

.accordion-button {
    font-family: "Kanit" !important;
    gap: 10px;
    color: #E86029 !important;
}

.accordion-button:not(.collapsed) {
    background-color: #e8602938 !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-item {
    font-family: "Kanit";
}

.mini-image-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.mini-image-container::-webkit-scrollbar-thumb {
    background: #E86029;
    border-radius: 5px;
}

@media screen and (max-width: 800px) {
    .product-detail {
        flex-direction: column;
    }

    .product-detail-images-container {
        width: 100%;
        position: unset !important;
        height: max-content;
    }

    .mini-image-container {
        width: 15%;
    }

    .product-detail-big-image {
        width: 82%;
    }

    .mini-image-container img {
        width: 80px;
        height: 80px;
        /* height: ; */
    }
    .mini-image-container iframe {
        width: 80px;
        height: 80px;
        /* height: ; */
    }

    .product-detialed {
        width: 100%;
        margin-top: 20px;
    }
}

@media screen and (max-width: 800px) {
    .mini-image-container {
        width: 20%;
    }

    .product-detail-big-image {
        width: 78%;
    }

}

@media screen and (max-width: 500px) {
    .mini-image-container {
        width: 20%;
    }

    .mini-image-container img {
        width: 60px;
        height: 60px;
        /* height: ; */
    }
    .mini-image-container iframe {
        width: 60px;
        height: 60px;
        /* height: ; */
    }

    .product-detail-big-image {
        width: 78%;
    }

}

@media screen and (max-width: 400px) {
    .product-detail-images-container {
        flex-direction: column;
    }

    .mini-image-container {
        width: 100%;
        display: flex;
        height: unset;
        gap: 20px;
        overflow-x: scroll;
        margin-bottom: 10px;
    }

    .mini-image-container img {
        width: 60px;
        height: 60px;
        /* height: ; */
    }
    .mini-image-container iframe {
        width: 60px;
        height: 60px;
        /* height: ; */
    }

    .product-detail-big-image {
        width: 100%;
    }

}