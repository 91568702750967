.category-container {
    width: 25%;
    position: relative;
}

.category-container h2 {
    font-size: 18px !important;
    font-family: "Kanit";
    font-weight: 600;
    color: black;
}

.category {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.category:hover .category-icon {
    color: #E86029;
}

.category:hover .category-text {
    color: #E86029;
}

.category-icon {
    color: #666;
    font-size: 14px;
}

.category-text {
    color: #666;
    margin: 0;
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
}
.category_image{
    width: 20px;
}
.subcategories {
    position: absolute;
    top: 0;
    left: 110%;
    background: white;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 200px;
    margin-right: 10px;
    border-radius: 5px;
}

.subcategory-text {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
    font-family: "Kanit";
    font-weight: 500;
}

.subcategory-text:hover {
    color: #E86029;
}

.more-category-container {
    cursor: pointer;
}

.more-category-container .more-category-icon {
    color: #E86029;
}

.more-category-container .more-category-text {
    color: #E86029;
    margin: 0;
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 800px) {
    .category-main {
        display: none;
    }

    .category-container {
        width: 100%;
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .category-container h2 {
        margin: 0;
    }
}