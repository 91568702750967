.register-main-container {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px 0;

}

.register-form-container {
    width: 70%;
    background-color: #f7f7f7;
    border-radius: 15px;
    padding: 25px;
    margin: 20px 0;
}

.register-input-div {
    width: 45%;
    position: relative;
}

.password-register-toggle-icon {
    position: absolute;
    right: 10px;
    top: 63%;
    transform: translateY(-50%);
    cursor: pointer;
}

.register-input {
    width: 100% !important;
    border: none !important;
    padding: 20px !important;
    border-radius: 12px !important;
    outline: none !important;
    color: #1e1e1e !important;
    font-size: 0.9rem !important;
    font-family: "Kanit" !important;
    font-weight: 600 !important;
    /* padding-bottom: 0 !important; */
}

.register-input::placeholder {
    color: #70757d !important;
    font-size: 0.9rem !important;
    font-family: "Kanit" !important;
    font-weight: 600 !important;
}

.register-input:focus {
    outline: none !important;
}

.register-btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.register-btn {
    border: none;
    background-color: #E86029;
    color: #fff;
    font-family: "Kanit";
    font-size: 16px;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 5px;
}

.authen-login {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: rgba(0, 0, 0, .7); */
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 999;
}

.authen-login-popup {
    background-color: #ebebeb;
    border-radius: 8px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    flex-direction: column;
    width: 400px;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 800px) {
    .register-form-container {
        width: 80%;
    }

    .register-input-div {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .register-form-container {
        width: 100%;
    }

    .register-input-div {
        width: 100%;
    }
}