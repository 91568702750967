.toptags-container {
    width: 25%;

}

.toptags-container h2 {
    font-size: 20px !important;
    font-family: "Kanit";
    font-weight: 600;
    color: black;
    border-bottom-width: 2px;
    border-bottom-color: #E86029;
    border-bottom-style: solid;
    width: 35%;
    padding-bottom: 10px;
    margin: 0;
}

.toptags-main {
    margin: 0;
    border: 1px solid #ebebeb;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    border-radius: 3px;
}

.tags {
    margin: 0 10px 10px 0;
    font-family: "Kanit";
    font-size: 12px;
    font-weight: 400;
    background: #eee;
    color: #222;
    padding: 5px 20px;
    transition: all 0.2s ease 0s;
    border-radius: 15px;
    cursor: pointer;
}

.tags:hover {
    background: #E86029;
    color: #fff;
}

@media screen and (max-width: 1280px) {
    .toptags-container h2 {
        font-size: 20px !important;
        font-family: "Kanit";
        font-weight: 600;
        color: black;
        border-bottom-width: 2px;
        border-bottom-color: #E86029;
        border-bottom-style: solid;
        width: 40%;
        padding-bottom: 10px;
        margin: 0;
    }
    
}

@media screen and (max-width: 1024px) {
    .toptags-container h2 {
        width: 14%;
    }
    .toptags-container {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 800px) {
    .toptags-container {
        width: 100%;
    }

    .toptags-container h2 {
        font-size: 20px !important;
        font-family: "Kanit";
        font-weight: 600;
        color: black;
        border-bottom-width: 2px;
        border-bottom-color: #E86029;
        border-bottom-style: solid;
        width: 18%;
        padding-bottom: 10px;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .toptags-container {
        width: 100%;
    }

    .toptags-container h2 {
        font-size: 20px !important;
        font-family: "Kanit";
        font-weight: 600;
        color: black;
        border-bottom-width: 2px;
        border-bottom-color: #E86029;
        border-bottom-style: solid;
        width: 28%;
        padding-bottom: 10px;
        margin: 0;
    }
}

@media screen and (max-width: 400px) {
    .toptags-container {
        width: 100%;
    }

    .toptags-container h2 {
        font-size: 20px !important;
        font-family: "Kanit";
        font-weight: 600;
        color: black;
        border-bottom-width: 2px;
        border-bottom-color: #E86029;
        border-bottom-style: solid;
        width: 30%;
        padding-bottom: 10px;
        margin: 0;
    }
}