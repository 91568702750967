.cart-container {
    width: 85%;
    margin: 0 auto;
    margin-top: 20px !important;
}

.bread-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.bread-icon {
    font-size: 12px;
    color: #999;
}

.bread-arrow {
    margin: 0;
    font-size: 12px;
    font-family: "Kanit";
    color: #999;
}

.bread-text {
    margin: 0;
    font-size: 14px;
    font-family: "Kanit";
    color: #E86029;
    cursor: pointer;
}

.shopping-cart-heading {
    font-weight: 700;
    font-family: "Kanit";
    color: #666666;
    font-size: 34px;
    margin-bottom: 20px;
}

.cart-product-image {
    width: 50px;
    height: 50px;
}

.table-head-text {
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 600;
}

.counter-input {
    border: none;
    /* width: 30px; */
    height: 35px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    /* border-radius: 5px; */
    background-color: #E9E9E9 !important;
    outline: none;
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 600;
}

.cart-remove-main {
    width: 30px;
    background-color: #d9534f !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-product-container>:not(caption)>*>* {
    border-width: 0 1px;
}

.cart-product-container>:not(caption)>*>* {
    padding: .5rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.cart-continue-btn {
    border: none !important;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 600;
    background-color: #E86029;
    color: #ffffff;
}

.cart-checkout-btn {
    border: none !important;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 600;
    background-color: #999;
    color: #ffffff;
}

.cart-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cart-continue-btn:hover {
    background-color: #495057;
}

.cart-checkout-btn:hover {
    background-color: #495057;
}

.small-container {
    overflow-x: none;
}

.cart-product-container {
    width: 100%;
}

.small-container ::-webkit-scrollbar {
    width: 0px;
}

@media only screen and (max-width: 800px) {
    .counter-input {
        border: none;
        width: 50%;
    }

    .table-head-text {
        font-family: "Kanit";
        font-size: 12px;
        font-weight: 500;
    }
}

@media only screen and (max-width: 400px) {
    .small-container {
        overflow-x: scroll;
    }

    .cart-product-container {
        width: 200% !important;
    }
}