.box {
    display: flex;
    width: 100%;
    height: 100vh;
    border: none;
    position: fixed;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    font-size: 66px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
    background: white;
  }
  .IMG {
    width: 250px;
  }
  /* 
  button {
    display: inline-block;
    background: transparent;
    border: 2px solid currentcolor;
    color: white;
    padding: 0.5em 1em;
    border-radius: 5px;
    font-size: calc(16px + 2vw);
  } */
  
  [class*="loader-"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
  }
  .loader-37 {
    border-right: 0.1em solid currentcolor;
    border-radius: 100%;
    color: #E86029;
    animation: loader-37 800ms linear infinite;
    bottom: 0;
  }
  .loader-37:before,
  .loader-37:after {
    content: "";
    width: 0.8em;
    height: 0.8em;
    display: block;
    position: absolute;
    top: calc(50% - 0.4em);
    left: calc(50% - 0.4em);
    border-left: 0.08em solid currentcolor;
    border-radius: 100%;
    animation: loader-37 400ms linear infinite reverse;
  }
  .loader-37:after {
    width: 0.6em;
    height: 0.6em;
    top: calc(50% - 0.3em);
    left: calc(50% - 0.3em);
    border: 0;
    border-right: 0.05em solid currentcolor;
    animation: none;
  }
  
  @keyframes loader-37 {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  