.login-container {
    background: url("../../images/login-backround.webp"), #000;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100vh;
    padding: 0px 70px;
}

.login-wrapper {
    /* backdrop-filter: blur(8px); */
    /* -webkit-backdrop-filter: blur(8px); */
    width: 400px;
    border-radius: 8px;
    padding: 30px;
    background: linear-gradient(180deg, #100202 0%, #370D05 25%, #722219 50%, #391613 75%, #180404 100%);
    /* text-align: center; */
    /* border: 1px solid rgba(255, 255, 255, 0.5); */
    position: relative;
}

.Login-input-div .lable {
    color: #fff !important;
}

.login-heading {
    text-align: center;
    font-size: 26px;
    font-family: "Kanit";
    font-weight: 700;
    margin: 10px 0;
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 10s linear infinite;
    animation: shine 10s linear infinite;
    background: linear-gradient(90deg, #fff 5%, #94343d 46%, #e86029);
    -webkit-background-clip: text;
}

.Login-input-div {
    width: 100%;
    position: relative;
}

.password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 70%;
    transform: translateY(-50%);
    cursor: pointer;
}

.not-account p {
    color: #fff;
}
.not-account p a:hover {
    color: #fff;
}

.not-account {
    text-align: end;
    color: #000;
    font-family: "Kanit";
    margin-top: 10px;
}

.login-btn-container {
    width: 100%;
}

.login-btn {
    border: none;
    background-color: #BA3B1A;
    color: #fff;
    font-family: "Kanit";
    font-size: 16px;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 5px;
    width: 100%;
}

.login-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media (max-width: 500px) {
    .login-container {
        justify-content: center !important;
        padding: 0 30px !important;
    }
}