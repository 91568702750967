.slider-container {
    width: 70%;
    margin: 0 auto;
}

@media screen and (max-width: 800px) {
    .slider-container {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}