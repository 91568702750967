.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #1B3356;
    overflow-x: hidden;
    /* Ensure no horizontal overflow */
    overflow-y: auto;
    /* Ensure no vertical overflow */
    transition: width 0.5s;
    /* Transition for sidebar width */
    padding-top: 60px;
}

.sidebar::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
    background: #E86029;
    border-radius: 10px;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
    background: #E86029;
}

.sidebar.opensidebar {
    width: 250px;
}

.categorysidebar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 10px; */
    cursor: pointer;
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    /* Smooth transitions */
    border-top: 1px solid #fff;
    /* padding-top: 10px;
    padding-bottom: 10px; */
    padding: 10px;
}

.sidebar.opensidebar .categorysidebar {
    opacity: 1;
    transform: translateX(0);
}

.categorysidebar-main {
    display: flex;
    align-items: center;
    gap: 10px;
}

.categorysidebar-icon {
    color: white;
}

.categorysidebar-text {
    font-size: 14px;
    font-family: "Kanit";
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    margin: 0;
    padding: 0 !important;
    transition: color 0.3s;
    /* Transition for hover effect */
}

.categorysidebar:hover .categorysidebar-icon,
.categorysidebar:hover .categorysidebar-text {
    color: #E86029 !important;
}

.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: opacity 0.5s;
    opacity: 0;
    pointer-events: none;
}

.sidebar-subcategory-text {
    border-bottom: 1px solid#666;
    padding: 5px 0;
    font-size: 14px;
    color: #666;
    font-family: "Kanit";
    font-weight: 500;
    margin: 0 !important;
}

.overlay.active {
    opacity: 1;
    pointer-events: auto;
}

.sidebar-input-dropdown {
    display: none;
   
}

.sidebar-input-dropdown input {
    border: none;
    outline: none;
    font-size: 13px;
    font-family: "Kanit";
    width: 50%;
    margin-right: 10px;
    color: #666;
}
.sidebar-input-dropdown input::placeholder {
    /* border: none;
    outline: none; */
    font-size: 13px;
    font-family: "Kanit";
    /* width: 50%; */
    /* margin-right: 10px; */
    color: #666;
}

.sidebar-input-dropdown button {
    border: none;
    outline: none;
    font-family: "Kanit";
    background-color: #E86029;
    padding: 10px;
    margin: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

@media screen and (max-height: 450px) {
    .sidebar {
        padding-top: 15px;
    }
}
@media screen and (max-width: 450px) {
    .sidebar-input-dropdown {
        width: 70% !important;
    }
}
@media screen and (max-width: 800px) {
    .sidebar-input-dropdown {
        display: flex !important;
        align-items: center;
        border: 1px solid #ebebeb;
        width: 50%;
        margin: 0 auto;
        padding-left: 10px;
        border-radius: 10px;
        justify-content: space-between;
        background: #fff;
        margin: 10px 0 !important;
        position: relative;
    }
}