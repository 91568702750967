.footer-main {
    background-color: #06142e;


}

.footer-main-div {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}

.footer-main-div img {
    width: 200px;
}

.footer-internal-link {
    list-style: none;
    gap: 10px;
    margin: 0;
    display: flex;
    color: #fff;
}

.footer-internal-link-li {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 600;
    margin-left: 20px;
    cursor: pointer;
}

.social-link {
    display: flex;
    gap: 10px;
}

.social-link-div {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.other-information-container {
    width: 85%;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-top: 30px;
    flex-wrap: wrap;
}

.footer-contact-info-container {
    width: 25%;
}

.footer-account-info-container {
    width: 15%;
}

.footer-account-info-container h6 {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 600;
    color: #fff;
}

.footer-instagram-container h6 {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 600;
    color: #fff;
}

.footer-instagram-container {
    width: 30%;
}

.footer-contact-info-container h6 {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 600;
    color: #fff;
}

.footer-underline {
    height: 2px;
    width: 60px;
    background-color: #E86029;
    margin: 0;

}

.footer-contact-info-list {
    list-style: none;
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
    color: #fff;
    /* margin: 0; */
    padding: 0;
    margin-top: 10px;

}


.footer-contact-info-list li {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.footer-account-info-list {
    list-style: none;
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 500;
    color: #fff;
    /* margin: 0; */
    padding: 0;
    margin-top: 10px;
}

.footer-account-info-list li {
    position: relative;
    margin-top: 10px;
    padding-left: 20px;
}

.footer-account-info-list li::before {
    font-family: "Kanit";
    content: "";
    font-size: 10px;
    position: absolute;
    top: 2px;
    left: 0px;
    color: #fff;
}

.footer-insta-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    border: 1px solid #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.footer-insta-image {
    width: 30%;
    height: 80px;
    object-fit: contain;
}

@media screen and (max-width: 800px) {

    .footer-instagram-container {
        width: 100%;
    }

    .footer-account-info-container {
        width: 18%;
    }

    .footer-contact-info-list li {
        font-size: 12px !important;
    }

    .footer-account-info-list li {
        font-size: 12px !important;
    }

    .footer-account-info-list li::before {
        font-size: 12px !important;
    }

    .footer-contact-info-container {
        width: 30%;
    }

    .footer-main-div img {
        width: 180px;
    }
}

@media screen and (max-width: 800px) {
    .footer-internal-link {
        display: none;
    }

    .footer-account-info-container {
        width: 100%;
    }

    .footer-contact-info-container {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .footer-main-div {
        flex-direction: column !important;
    }
}