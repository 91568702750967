.wishlits-container {
    width: 85%;
    margin: 30px auto;
}

.wishlist-main-heading {
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 3s linear infinite;
    animation: shine 3s linear infinite;
    background: linear-gradient(90deg, #E86029 5%, #000 46%, #E86029 100%);
    -webkit-background-clip: text;
    font-family: "Kanit", sans-serif;
    font-size: 30px;
    letter-spacing: .1rem;
    font-weight: 500;
    text-align: center;
}

.heading-and-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wishlist-search-container {
    width: 25%;
    border: 1px solid #ebebeb;
    padding: 10px;
    border-radius: 8px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wishlist-search-container input {
    border: none !important;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    font-family: "Kanit";
    width: 90%;
}

.wishlist-product-container {
    display: flex;
    align-items: center;
    /* justify-content: space-; */
    flex-wrap: wrap;
}

.wishlits-product-main {
    background: #fff;
    border-radius: 8px;
    width: 23%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    margin: 10px 10px;

}
.wishlits-product-main:hover{
    transform: scale(1.1);
}
.wishlist-product-image {
    cursor: pointer;
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.wishlist-product-name {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 400;
    margin-top: 10px !important;
    margin: 0;
    color: #222;
}

.wishlist-product-price {
    font-size: 16px;
    font-family: "Kanit";
    font-weight: 600;
    color: #E86029;
}

.wishlist-addcart {
    background: #666666;
    border-radius: 3px;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 80%;
}

.wishlist-addcart:hover {
    background-color: #E86029;
}

.wishlist-addcart .trending-icon {
    color: #fff;
    font-size: 12px;
}

.wishlist-addcart p {
    color: #fff;
    font-size: 12px;
    font-family: "Kanit";
    margin: 0;
    margin-left: 8px;

}

@media screen and (max-width: 1024px) {
    .wishlits-product-main {
        width: 30%;
    }
}

@media screen and (max-width: 800px) {
    .wishlits-product-main {
        width: 30%;
    }

    .wishlist-search-container {
        width: 35%;
    }
}

@media screen and (max-width: 600px) {
    .wishlits-product-main {
        width: 45%;
    }

    .wishlist-search-container {
        width: 45%;
    }
}

@media screen and (max-width: 500px) {
    .wishlits-product-main {
        width: 90%;
    }

    .wishlist-search-container {
        display: none;
    }

    .heading-and-input-container {
        margin: 20px 0;
    }
    .wishlist-main-heading{
        font-size: 26px;
    }
}

@keyframes shine {
    0% {
        background-position: 0%;
    }

    60% {
        background-position: 600px;
    }

    100% {
        background-position: 600px;
    }
}