.otp-main {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5F5;
    height: 100vh;
}

.otp-container {
    background: #fff;
    width: 30%;
    padding: 60px 20px;
    border-radius: 15px;
}

.enter-code-heading {
    font-size: 22px;
    font-family: "Kanit";
    font-weight: 600;
    color: #222;
}

.sent-email-text {
    font-size: 14px;
    font-family: "Kanit";
    font-weight: 400;
    margin: 8px 0;
}

.otp-input {
    background: transparent;
    border: 1px solid #222;
    font-family: "Kanit";
    font-weight: 500;
    font-size: 14px;
    margin: 10px 0;
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
}

.otp-submit-btn {
    border: none !important;
    border-radius: 12px;
    background: #E86029;
    width: 100%;
    padding: 10px;
    text-align: center;
    color: #fff;
    font-family: "Kanit";
    font-size: 14px;
    font-weight: 600;
}

@media (max-width: 800px) {

    .otp-container {
        width: 60%;
    }
}
@media (max-width: 600px) {

    .otp-container {
        width: 80%;
    }
}